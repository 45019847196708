@import '../../../../scss/theme-bootstrap';

// new for 2018 Creative Refresh
// @todo rewrite for the linter - this is WIP
$max-widths: 960 1070 1280 1800;
.product-grid__wrapper {
  @include content-contain;
  &:has(.product-grid--carousel) {
    padding-inline: 40px;
    @include breakpoint($medium-up) {
      // Allow space for arrows.
      padding-inline: 105px;
    }
  }
  .basic-grid--full-width & {
    max-width: 100%;
  }
  @each $value in $max-widths {
    .basic-grid--max-width-#{$value} & {
      max-width: #{$value}px;
    }
  }
  .product-grid-v3 {
    // Get's the max width from formatter on the container.
    .product-grid__content {
      max-width: unset;
    }
  }
}

.product-grid.product-grid-v3 {
  width: 100%;
  &.product-grid--carousel {
    margin-inline: auto;
    // CLS logic, to only show final items on page load.
    &.product-grid--small-cols-1 {
      .product-brief__cta-add-to-bag {
        // box-shadow overflows over the prev slide.
        padding-inline-start: 1px;
      }
      .product-grid__item ~ .product-grid__item {
        display: none;
        @include breakpoint($medium-up) {
          display: block;
        }
      }
      .slick-initialized {
        .product-grid__item ~ .product-grid__item {
          display: block;
        }
      }
    }
    &.product-grid--medium-cols-3 {
      .product-grid__item:nth-child(3) ~ .product-grid__item {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
      .slick-initialized {
        .product-grid__item:nth-child(3) ~ .product-grid__item {
          @include breakpoint($medium-up) {
            display: block;
          }
        }
      }
    }
    &.product-grid--medium-cols-4 {
      .product-grid__item:nth-child(4) ~ .product-grid__item {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
      .slick-initialized {
        .product-grid__item:nth-child(4) ~ .product-grid__item {
          @include breakpoint($medium-up) {
            display: block;
          }
        }
      }
    }
    // End CLS logic
  }
  .product-grid {
    &__item {
      border-bottom: none;
      width: 100%;
      margin: 1em 0 3em 0;
      @include breakpoint($medium-up) {
        float: left;
        margin: 0;
        padding: 0 0.5em 3em 0.5em;
        width: 33.333%;
      }
      .product-grid--carousel & {
        border-bottom: none;
      }
      &--promo {
        margin-bottom: 20px;
        @include breakpoint($medium-up) {
          margin-bottom: 0;
        }
      }
    }
  }
  &.product-grid {
    &--small-cols-2,
    &--small-cols-3 {
      .product-grid__item {
        float: left;
      }
    }
    &--small-cols-2 .product-grid__item {
      width: 50%;
    }
    &--small-cols-3 .product-grid__item {
      width: 33.333%;
    }
    &--medium-cols-1 .product-grid__item {
      @include breakpoint($medium-up) {
        float: none;
        padding: 0;
        width: auto;
      }
    }
    &--medium-cols-2 .product-grid__item {
      @include breakpoint($medium-up) {
        width: 50%;
      }
    }
    &--medium-cols-3 .product-grid__item {
      @include breakpoint($medium-up) {
        width: 33.333%;
      }
    }
    &--medium-cols-4 .product-grid__item {
      @include breakpoint($medium-up) {
        width: 25%;
      }
    }
  }

  .carousel-controls {
    top: 25%;
    @include breakpoint($landscape-up) {
      top: 27%;
    }
  }
  .slick-arrow {
    top: 30%;
    @include breakpoint($medium-up) {
      top: 22%;
    }
  }
  .slick-prev {
    @include bordered-triangle(33px, $color-white, $ldirection, $color-black);
    #{$ldirection}: -38px;
    position: absolute;
    height: 35px;
    @include breakpoint($medium-up) {
      #{$ldirection}: -80px;
    }
  }
  .slick-next {
    @include bordered-triangle(33px, $color-white, $rdirection, $color-black);
    position: absolute;
    #{$rdirection}: -38px;
    height: 35px;
    @include breakpoint($medium-up) {
      #{$rdirection}: -80px;
    }
  }
}

.product-grid-v3 .product-grid__item {
  .mpp-container--has-filters & {
    // initially hide items only if mixitup filters is enabled
    display: hidden;
    // @todo finalize
    border: none;
    // display: block;
  }
}
